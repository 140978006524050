import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar.js';
import Home from './pages/Home';
import QueryByDNI from './pages/QueryByDNI';
import QueryByRUC from './pages/QueryByRUC';
import LoginForm from './components/LoginForm';
  
function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const handleLogin = (username, password) => {
    // Simula autenticación (puedes conectar a tu API)
    if (username === 'admin' && password === 'password') {
      setIsAuthenticated(false);
    }
  };

  return (
    
    <Router>
     
    <div className="d-flex">
          <Sidebar />
          <div className="container-fluid p-4">
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/query-dni" element={<QueryByDNI />} />
              <Route path="/query-ruc" element={<QueryByRUC />} />
            </Routes>
          </div>
        </div>
    </Router>
  );
}

export default App;
