import React, { useState } from 'react';
import axios from 'axios';

function QueryByDNI() {
  const [dni, setDni] = useState('');
  const [result, setResult] = useState(null);

  const handleQuery = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_RENIEC;
      const response = await axios.get(`${apiUrl}/${dni}`);
      setResult(response.data);
    } catch (error) {
      alert('Error al consultar DNI');
    }
  };

  return (
    <div className="container mt-4">
      <h2>CONSULTA POR DNI</h2>
      <div className="mb-3">
        <label htmlFor="dniInput" className="form-label">Ingrese DNI:</label>
        <input
          type="text"
          className="form-control"
          id="dniInput"
          value={dni}
          onChange={(e) => setDni(e.target.value)}
          maxLength={8}
          placeholder="Ingrese un DNI válido"
        />
      </div>
      <button className="btn btn-primary" onClick={handleQuery}>Consultar</button>
      {result && (
        <div className="mt-4">
          <h5 className="mb-3">Resultado de la consulta:</h5>
          <table className="table table-striped table-hover">
            <thead className="table-success">
              <tr>
                <th>TITULOS</th>
                <th>DESCRIPCIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nombres y Apellidos</td>
                <td>{result.data.fullName}</td>
              </tr>
              <tr>
                <td>Dni</td>
                <td>{result.data.dni}</td>
              </tr> 
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default QueryByDNI;
