import React from 'react';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  return (
    <div className="sidebar">
      <div className="logo">
        <div className="logo-container">
          <img 
            src="/logo.png" 
            alt="Logo" 
            className="logo-image"
          />
        </div>
      </div>
      <ul className="menu">
        <li className="nav-item">
          <NavLink to="/home" className="nav-link">Inicio</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/query-dni" className="nav-link">Reniec</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/query-ruc" className="nav-link">Sunat</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
